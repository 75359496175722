import { createAction, props } from '@ngrx/store';
import { LoggedInUser } from '../models/auth.model';

export enum AuthActionTypes {
    LOGIN = '[Auth] Login',
    LOGOUT = '[Auth] Logout'
}

export const login = createAction(
    AuthActionTypes.LOGIN,
    props<{ user: LoggedInUser }>()
);

export const logout = createAction(AuthActionTypes.LOGOUT);
