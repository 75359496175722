import { LoggedInUser } from '../models/auth.model';
import { GetWishListsResult } from '../../../typings/api';
import { Injectable } from '@angular/core';
import { USER_KEY, WISHLIST_KEY } from '../constants/auth.constants';
import { UserService } from '../../user/services/user.service';
import { WishlistService } from '../../wish-list/services/wishlist.service';
import { Wishlist } from '../../wish-list/models/wishlist.model';
import { Store } from '@ngrx/store';
import * as AuthActions from '../store/auth.actions';
import * as WishListActions from '../../wish-list/store/wish-list.actions';
import * as ProfileActions from '../../profile/store/profile.actions';
import { AppState } from '../../app/store/app.reducers';
import { CacheService } from '../../app/services/cache.service';
import { Router } from '@angular/router';
import { toggleGlobalSpinner } from '../../app/store/actions/ui.actions';
import { HomeRoutesPaths } from '../../home/home.routes';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class SessionService {
    constructor(
        private store: Store<AppState>,
        private wishlistService: WishlistService,
        private userService: UserService,
        private cacheService: CacheService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    startSession(user: LoggedInUser, wishList?: Wishlist) {
        this.cacheService.clearCache();
        this.store.dispatch(AuthActions.login({ user }));
        this.dialog.closeAll();

        if (wishList) {
            this.store.dispatch(WishListActions.setWishlist({ wishList }));
        } else {
            this.wishlistService
                .getWishList()
                .subscribe((wishListResult: GetWishListsResult) =>
                    this.store.dispatch(
                        WishListActions.setWishlist({
                            wishList:
                                this.wishlistService.toWishlist(wishListResult)
                        })
                    )
                );
        }

        if (!user.settings) {
            this.userService.getProfileSettings().subscribe(
                (result) =>
                    this.store.dispatch(
                        ProfileActions.updateSettings({
                            locale: result.settings.locale,
                            allowFollowing: result.settings.allow_following // allowChat:
                            // result.settings.chat_enabled
                        })
                    ),
                () => {
                    console.debug('error getting profile setting');
                }
            );
        }
    }

    clearSession() {
        this.cacheService.clearCache();

        // Update Analytics
        // this.$analytics.setUsername('');
        this.store.dispatch(toggleGlobalSpinner({ toggle: false }));
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(WISHLIST_KEY);
        this.store.dispatch(AuthActions.logout());

        // Navigate out of logged-in user pages
        if (/profile|wish/.test(location.pathname)) {
            this.router.navigate([`/${HomeRoutesPaths.HOME}`]);
        }
    }

    /**
     * Attempt to restore previous active session
     */
    getSession() {
        const user: LoggedInUser = JSON.parse(localStorage.getItem(USER_KEY)!);
        const wishlist: Wishlist = JSON.parse(
            localStorage.getItem(WISHLIST_KEY)!
        );

        if (user) {
            this.startSession(user, wishlist);
        }
    }
}
