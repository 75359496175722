import { environment } from '@env';

export const USER_KEY = environment.production ? 'user_prod' : 'user_staging';
export const WISHLIST_KEY = environment.production
    ? 'user_wishlist_prod'
    : 'user_wishlist_staging';
export const GATE_USER_COOKIE_KEY = environment.production
    ? 'usr_id'
    : 'staging-usr_id';
export const FB_APP_ID = '495122000642590';
